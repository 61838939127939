.form-jalan {
  display: flex;
  margin-bottom: 1rem;
  position: static;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 3rem;
  width: auto;
}

.periksa label.text {
  font-size: 1rem;
  color: #000000;
  font-weight: 700;
  position: relative;
  top: 0.5rem;
  padding: 0 3px;
  background: #fff;
  width: fit-content;
}

.coolinput input[type="text"].input {
  padding: 11px 10px;
  font-size: 0.75rem;
  border: 2px#0d9488;
  border-radius: 5px;
}

.periksa:focus {
  outline: none;
}

.status {
  display: flex;
  flex-direction: column;
}

.form-jalan {
  display: flex;
  margin-bottom: 1rem;
  position: static;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 3rem;
  width: auto;
}

.form-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  width: 16rem;
  padding-top: 0;
}
.periksa {
  width: 9rem;
  display: flex;
  gap: 1rem;
  height: 3rem;
  border: 1px solid#0d9488;
  padding: 6px;
  border-radius: 8px;
  margin-bottom: 0.5rem;
}

.periksa option {
  margin-bottom: 1rem;
}
.tanggal {
  border: 1px solid #0d9488;
  border-radius: 8px;
}
.option {
  margin-top: 1rem;
}
.filter {
  display: flex;
  justify-content: flex-end;
  gap: 2rem;
  width: 32rem;
}
.text {
  font-size: 0.75rem;
  color: #707070;
  font-weight: 400;
  position: relative;
  top: 0.5rem;
  margin: 0 0 0 7px;
  padding: 0 3px;
  width: fit-content;
}

.jalan-row {
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
}

.form-jalan {
  width: 33%;
  display: flex;
  flex-direction: column;
}

.btn-submit {
  margin-top: 2rem;
  font-weight: 600;
  color: white;
  width: 20rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
  background-color: #0d9488;
}

.periksa label.text {
  font-size: 1rem;
  color: #000000;
  font-weight: 700;
  position: relative;
  top: 0.5rem;
  margin: 0 0 0 7px;
  padding: 0 3px;
  width: fit-content;
}

.coolinput input[type="text"].input {
  padding: 11px 10px;
  font-size: 0.75rem;
  border: 2px #818cf8 solid;
  border-radius: 5px;
}

.periksa:focus {
  outline: none;
}

.status {
  display: flex;
  flex-direction: column;
}

.datepicker {
  size: 12px;
  border-radius: 8px;
  margin: 0;
  width: 10rem;
  cursor: pointer;
}

.form-input {
  display: flex;
  margin-right: 4rem;
  width: 100%;
  flex-direction: column;
  border: 2px solid #0d9488;
  border-radius: 8px;
  padding: 2rem;
  justify-content: space-between;
}

.dropdown-container {
  width: 180px;
  border: 1px solid #0d9488;
  border-radius: 8px;
}

.nama-field {
  border: 1px solid #0d9488;
  height: 3rem;
  width: 6rem;
  padding: 8px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 8px;
}
.label-text {
  font-size: 1rem;
  font-weight: 600;
}
.bulan-field {
  border: 1px solid #0d9488;
  height: 3rem;
  width: 10rem;
  padding: 8px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 8px;
}
.form-field {
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}
.form-row {
  width: 100%;
  margin-top: 18px;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  align-items: center;
  z-index: 99;
  justify-content: space-between;
}
