@tailwind base;
@tailwind components;
@tailwind utilities;

/* @layer base {
  .row-table {
    @apply w-8; */
/* styles.css */
.toast-container {
  z-index: 9999;
}

.toast-item {
  background-color: #f87171;
  color: #fff;
  border-radius: 8px;
  padding: 16px;
}

.toast-item svg {
  fill: #fff;
}

.toast-item button {
  color: #fff;
  opacity: 0.7;
}

@layer base {
  .card-presensi {
    @apply container mx-auto mb-16 mt-5;
  }
  .title {
    @apply font-bold text-blue-500 text-4xl mb-4;
  }
}

@media (max-width: 12px) {
  body {
    display: none;
  }
}
