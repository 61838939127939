.detail-card-main {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  background-color: white;
  border: 2px solid #0d9488;
  border-radius: 8px;
  position: relative;
  height: 24rem;
}

.detail-card-main-body {
  width: 100%;
  padding: 2rem 1rem;
  height: 14rem;
  gap: 12px;
  background: rgb(20, 184, 166);
  background: linear-gradient(
    90deg,
    rgba(20, 184, 166, 1) 52%,
    rgba(22, 185, 167, 1) 53%,
    rgba(94, 234, 212, 1) 100%
  );
  display: flex;
  color: white;
  justify-content: space-between;
  align-items: flex-start;
}

.card-title {
  font-size: 2rem;
  font-weight: 600;
}
.detail-card-main-foot {
  top: 50%;
  display: flex;
  position: absolute;
  justify-content: center;
  gap: 2rem;
  align-items: center;
  background-color: transparent;
  width: 100%;
  height: auto;
}

.detail-card-foot-content {
  display: flex;
  height: 5rem;
  width: 12rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 1px solid #5eead4;
  border-radius: 8px;
  box-shadow: 0px 24px 15px -3px rgba(0, 0, 0, 0.1);
}

.title-2 {
  font-size: 1.3rem;
  font-weight: 500;
  margin-bottom: 1rem;
}
.title-3 {
  font-size: 1.2rem;
  font-weight: 400;
  margin-bottom: 1rem;
}
.detail-body-content-1 {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: auto;
}

.detail-body-content-2 {
  border: 1px solid #0d9488;
  width: 17rem;
  height: 3rem;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  color: #0d9488;
  font-weight: 500;
  border-radius: 8px;
  padding-top: 6px;
  align-items: flex-start;
}

.btn-input {
  width: 15rem;
  height: 3rem;
  background-color: #14b8a6;
  color: white;
  font-weight: 500;
  border-radius: 8px;
  border: none;
}

.btn-group {
  margin-top: 2rem;
  padding-top: 2rem;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  gap: 2rem;
  margin-left: 2rem;
}

.kalender-main {
  display: flex;
  width: auto;
  height: auto;
  padding: 2rem;
}

.table-kalender {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  width: 100%;
  height: auto;
  padding: 1rem;
  overflow-x: scroll;
  border: 1px solid #0d9488;
  border-radius: 8px;
}

.kalender-content {
  display: flex;
  flex-direction: column;
  width: 8rem;
  height: auto;
  justify-content: flex-start;
  align-items: center;
}

.field-table {
  padding: 12px;
  height: 2rem;
  width: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  color: white;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-color: #0d9488;
}

.row-table {
  padding: 12px;
  width: 100%;
  height: 2rem;
  display: flex;
  border: 1px solid #0d9488;
  justify-content: center;

  align-items: center;
  background-color: white;
}

.dropdown-container {
  width: 180px;
  border: 1px solid #0d9488;
  border-radius: 8px;
}
